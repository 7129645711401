<template>
  <Bread BreadTitle="金光明最勝王經"></Bread>

  <div class="row">
    <div class="col-md-3">
      <div class="input-group">
        <span class="input-group-text">進度</span>
        <select v-model="basedata.selected" class="form-control" id="sel1">
          <option v-for="(n, index) in basedata.MoonData" :key="index">
            {{ n.ID }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-9"></div>
  </div>
  <iframe
    width="350"
    height="300"
    :src="selectedUrl"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
import { onMounted, reactive, computed, watch } from "vue";
import Bread from "../components/Bread.vue";
export default {
  components: {
    Bread,
  },
  setup() {
    const selectedUrl = computed(() => {
      let vFind = basedata.MoonData.find((x) => x.ID == basedata.selected);

      if (vFind == undefined) return "";

      return vFind.Source;
    });
    const basedata = reactive({
      selected: null,
      MoonData: [
        {
          ID: "1",
          Source: "https://www.youtube.com/embed/0VPlO8p4GaY",
        },
        {
          ID: "2",
          Source: "https://www.youtube.com/embed/vssM2iiKpNs",
        },
        {
          ID: "3",
          Source: "https://www.youtube.com/embed/zJFwUd5ceys",
        },
        {
          ID: "4",
          Source: "https://www.youtube.com/embed/Jl3BajMY47k",
        },
        {
          ID: "5",
          Source: "https://www.youtube.com/embed/LmIWzilVmxU",
        },
        {
          ID: "6",
          Source: "https://www.youtube.com/embed/K3rx05Fc2A4",
        },
        {
          ID: "7",
          Source: "https://www.youtube.com/embed/OWZj8AaTtc8",
        },
        {
          ID: "8",
          Source: "https://www.youtube.com/embed/QjPFv6XiLh0",
        },
        {
          ID: "9",
          Source: "https://www.youtube.com/embed/EJoVVEpLUtI",
        },
        {
          ID: "10",
          Source: "https://www.youtube.com/embed/X5jvW3AEl3s",
        },
      ],
    });

    onMounted(() => {
      //預設值
      //basedata.selected = 1;

      var vRecordLocalStoragegoldenBook = localStorage.getItem(
        "goldenSelected"
      );

      if (vRecordLocalStoragegoldenBook == null)
        vRecordLocalStoragegoldenBook = 1;

      basedata.selected = vRecordLocalStoragegoldenBook;
    });

    watch(
      () => basedata.selected,
      (vNew, vOld) => {
        console.log("basedata.selected-vNew", vNew);
        console.log("basedata.selected-vOld", vOld);

        //存 local storage
        localStorage.setItem("goldenSelected", vNew);
      }
    );

    return { basedata, selectedUrl };
  },
};
</script>

<style>
</style>